import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
} from "@angular/core";
import { ProfileApiI, ProfileI } from "../../pojo/ProfileI";
import { TimeModelI } from "../../pojo/TimeModelI";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { PatternEndChoices, ProfileUtil } from "./profile.util";

@TypeManagerDecorator("s25-ng-date-pattern")
@Component({
    selector: "s25-ng-date-pattern",
    template: `
        <div *ngIf="this.init" [attr.data-type]="type">
            <span class="ngBold ngRed" *ngIf="this.overlapError">{{ this.overlapError }}</span>
            <div class="repeats-every" style="display: flex;">
                <label class="c-margin-bottom--half labelMinWidth" for="repeatsEvery">Repeats every</label>
                <s25-generic-dropdown
                    [items]="this.repeatsOptions"
                    [(chosen)]="this.profileModel.repeatsEvery"
                    (chosenChange)="repeatsEveryChange()"
                    [searchEnabled]="false"
                    class="maxWidth200"
                ></s25-generic-dropdown>
            </div>
            <div *ngIf="this.type === 'weekly'" class="">
                <p class="c-margin-top--half">Repeats on</p>
                <span *ngFor="let dow of this.profileModel.daysOfWeek" class="c-padding-right--half">
                    <s25-ng-checkbox
                        [(modelValue)]="dow.chosen"
                        (modelValueChange)="this.onChange()"
                        [labelId]="dow.itemName"
                        [labelClass]="'inline'"
                        ><span>{{ dow.itemName }}</span></s25-ng-checkbox
                    >
                </span>
            </div>
            <div *ngIf="this.type === 'monthly'" class="c-margin-bottom--single">
                <label class="c-margin-bottom--half c-margin-top--half">
                    <s25-ng-radio
                        [value]="'day'"
                        [(modelValue)]="this.profileModel.repeatBy"
                        [name]="'repeatBy-' + id"
                        (modelValueChange)="this.checkOverlap()"
                        class="labelMinWidth"
                    >
                        <span class="ngInlineBlock c-margin-right--quarter">Repeat by Day</span>
                    </s25-ng-radio>
                </label>
                <div *ngFor="let rep of this.profileModel.repeatByDay" class="repeat-by-day">
                    <label class="c-margin-right--half">
                        <span>Repeat</span>
                        <s25-generic-dropdown
                            [items]="this.repeatByDayStarts"
                            [(chosen)]="rep.repeatStart"
                            (chosenChange)="this.checkOverlap()"
                            [searchEnabled]="false"
                        ></s25-generic-dropdown>
                    </label>
                    <label>
                        <span>from</span>
                        <s25-generic-dropdown
                            [items]="this.repeatByDayFroms"
                            [(chosen)]="rep.repeatFrom"
                            (chosenChange)="checkOverlap()"
                            [searchEnabled]="false"
                        ></s25-generic-dropdown>
                    </label>
                    <button
                        class="aw-button aw-button--danger aw-button--flat c-margin-top--quarter"
                        (click)="this.repeatByDayRemove(rep)"
                    >
                        Remove Day
                    </button>
                </div>
                <button class="aw-button aw-button--outline c-margin-top--half" (click)="this.addRepeatByDay()">
                    Add Repeat Day
                </button>
                <div>
                    <label class="c-margin-top--single c-margin-bottom--half">
                        <s25-ng-radio
                            [value]="'position'"
                            [(modelValue)]="this.profileModel.repeatBy"
                            [name]="'repeatBy-' + id"
                            (modelValueChange)="this.checkOverlap()"
                            class="labelMinWidth"
                        >
                            <span class="ngInlineBlock c-margin-right--quarter">Repeat by Position</span>
                        </s25-ng-radio>
                    </label>
                    <div *ngFor="let rep of this.profileModel.repeatByPosition" class="repeat-monthly">
                        <fieldset class="repeatByPosition">
                            <!--<legend>Repeat day pattern</legend>-->
                            <div>
                                <label for="repeatStart">
                                    <span>Repeat on the</span>
                                </label>
                                <s25-generic-dropdown
                                    class="position"
                                    [items]="this.repeatByPositionStarts"
                                    [(chosen)]="rep.repeatStart"
                                    (chosenChange)="checkOverlap()"
                                    [searchEnabled]="false"
                                ></s25-generic-dropdown>
                            </div>
                            <s25-generic-dropdown
                                [items]="this.repeatByPositionFroms"
                                [(chosen)]="rep.repeatFrom"
                                (chosenChange)="checkOverlap()"
                                [searchEnabled]="false"
                            ></s25-generic-dropdown>
                            <button
                                class="aw-button aw-button--danger aw-button--flat"
                                (click)="this.repeatByPositionRemove(rep)"
                            >
                                Remove Position
                            </button>
                        </fieldset>
                    </div>
                    <button
                        class="aw-button aw-button--outline c-margin-top--half"
                        (click)="this.addRepeatByPosition()"
                    >
                        Add Repeat Position
                    </button>
                </div>
            </div>

            <div *ngIf="hasAnyEndDt">
                <label class="c-margin-top--half">
                    <s25-ng-radio
                        [value]="'none'"
                        [(modelValue)]="this.profileModel.repeatEnd"
                        [name]="'repeatUntil-' + id"
                        class="labelMinWidth"
                        (modelValueChange)="onChange()"
                    >
                        <span>Any end date</span>
                    </s25-ng-radio>
                </label>
            </div>
            <div *ngIf="hasDateEndDt">
                <label class="rose-patternPicker-label c-margin-top--half">
                    <s25-ng-radio
                        [value]="'date'"
                        [(modelValue)]="this.profileModel.repeatEnd"
                        [name]="'repeatUntil-' + id"
                        class="labelMinWidth"
                        (modelValueChange)="onChange()"
                    >
                        <span>Repeats through</span>
                    </s25-ng-radio>
                </label>
                <s25-ng-editable-date
                    class="c-displayInlineBlock"
                    [(val)]="this.repeatsThroughBean.date"
                    (valChange)="this.repeatsThroughDateChange()"
                    [alwaysEditing]="true"
                ></s25-ng-editable-date>
                <div class="ngBold ngRed" *ngIf="dateError">{{ dateError }}</div>
            </div>

            <div *ngIf="hasIterations">
                <label class="rose-patternPicker-label">
                    <s25-ng-radio
                        [value]="'iterations'"
                        [(modelValue)]="this.profileModel.repeatEnd"
                        [name]="'repeatUntil-' + id"
                        class="labelMinWidth"
                        (modelValueChange)="onChange()"
                    >
                        <span>Ends after</span>
                    </s25-ng-radio>
                </label>
                <div class="c-displayInlineBlock">
                    <input
                        type="number"
                        [(ngModel)]="this.profileModel.endsAfter"
                        (change)="this.endsAfterChange()"
                        class="c-input iteration-input c-margin-right--quarter"
                    />
                    <span>iterations</span>
                </div>
            </div>
        </div>
    `,
    styles: `
        .maxWidth200 {
            max-width: 200px;
            display: block;
        }

        .labelMinWidth {
            min-width: 11em;
            display: block;
        }

        .repeatByPosition {
            display: flex;
            gap: 0.625rem;
            align-items: end;
            flex-wrap: wrap;
        }

        .repeatByPosition .position {
            min-width: 11em;
            display: block;
        }

        .repeatByPosition > button {
            padding-left: 0;
        }
    `,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25DatePatternComponent implements OnInit {
    @Input() type: "daily" | "weekly" | "monthly";
    @Input() profileModel: ProfileI;
    @Input() timeModel: TimeModelI; //Acts as the start date for the profile
    @Input() profileCode: string;
    @Input() apiBean: ProfileApiI;
    @Input() patternEndChoices: PatternEndChoices[] = ["date", "iterations"]; //none, date, iterations

    @Output() apiBeanChange = new EventEmitter<ProfileApiI>();

    static patternCount: number = 0;

    init: boolean;
    dateError: string;
    overlapError: string;
    id: string;
    hasIterations: boolean;
    hasAnyEndDt: boolean;
    hasDateEndDt: boolean;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        S25DatePatternComponent.patternCount++;
        this.id = "ngDatePattern" + S25DatePatternComponent.patternCount;
    }

    repeatsThroughBean: any = {};
    repeatsOptions: any[];
    repeatByDayStarts: any[];
    repeatByDayFroms = [
        { itemName: "Start of the Month", itemId: "start" },
        { itemName: "End of the Month", itemId: "end" },
    ];
    repeatByPositionStarts = [
        { itemName: "First", itemId: 1 },
        { itemName: "Second", itemId: 2 },
        { itemName: "Third", itemId: 3 },
        { itemName: "Fourth", itemId: 4 },
        { itemName: "Fifth", itemId: 5 },

        { itemName: "Last", itemId: -1 },
        { itemName: "Second-to-last", itemId: -2 },
        { itemName: "Third-to-last", itemId: -3 },
        { itemName: "Fourth-to-last", itemId: -4 },
        { itemName: "Fifth-to-last", itemId: -5 },
    ];
    repeatByPositionFroms = [
        { itemName: "Sunday", abbr: "SU", itemId: 0 },
        { itemName: "Monday", abbr: "MO", itemId: 1 },
        { itemName: "Tuesday", abbr: "TU", itemId: 2 },
        { itemName: "Wednesday", abbr: "WE", itemId: 3 },
        { itemName: "Thursday", abbr: "TH", itemId: 4 },
        { itemName: "Friday", abbr: "FR", itemId: 5 },
        { itemName: "Saturday", abbr: "SA", itemId: 6 },
    ];
    resetErrors() {
        this.dateError = "";
        this.overlapError = "";
    }
    repeatByDayRemove(rep: any) {
        S25Util.array.inplaceRemoveByProp(this.profileModel.repeatByDay, "itemId", rep.itemId);
        this.onChange();
    }
    repeatByPositionRemove(rep: any) {
        S25Util.array.inplaceRemoveByProp(this.profileModel.repeatByPosition, "itemId", rep.itemId);
        this.onChange();
    }
    addRepeatByDay() {
        this.profileModel.repeatBy = "day";
        this.profileModel.repeatByDay.push({
            itemId: ProfileUtil.getNextCounter(),
            repeatStart: this.repeatByDayStarts[0],
            repeatFrom: this.repeatByDayFroms[0],
        });
        this.checkOverlap();
        this.onChange();
    }
    addRepeatByPosition() {
        this.profileModel.repeatBy = "position";
        this.profileModel.repeatByPosition.push({
            itemId: ProfileUtil.getNextCounter(),
            repeatStart: this.repeatByPositionStarts[0],
            repeatFrom: this.repeatByPositionFroms[0],
        });
        this.checkOverlap();
        this.onChange();
    }
    checkOverlap() {
        if (ProfileUtil.hasOverlaps(this.timeModel, this.profileModel, this.repeatsThroughBean.date)) {
            this.overlapError =
                "Occurrence dates are overlapping. This can happen when repeating an overnight occurrence or from pre/setup/post/takedown times causing the occurrence to span midnight";
        } else {
            this.overlapError = "";
            this.onChange();
        }
    }

    repeatsEveryChange() {
        if (ProfileUtil.hasOverlaps(this.timeModel, this.profileModel, this.repeatsThroughBean.date)) {
            this.overlapError =
                "Occurrence dates are overlapping. This can happen when repeating an overnight occurrence or from pre/setup/post/takedown times causing the occurrence to span midnight";
            this.profileModel.repeatsEvery = this.repeatsOptions[0];
        }
        this.cd.detectChanges();
        this.onChange();
    }
    repeatsThroughDateChange() {
        this.resetErrors();
        this.profileModel.repeatEnd = "date";
        if (S25Util.date.getDate(this.timeModel.evStartDt) > S25Util.date.getDate(this.repeatsThroughBean.date)) {
            this.dateError =
                "When repeating through to a final date, please choose a final date that is greater than the initial start date";
            ProfileUtil.resetThroughDate(this.repeatsThroughBean, this.profileModel, null, this.timeModel);
        }
        if (ProfileUtil.hasOverlaps(this.timeModel, this.profileModel, this.repeatsThroughBean.date)) {
            this.overlapError =
                "Occurrence dates are overlapping. This can happen when repeating an overnight occurrence or from pre/setup/post/takedown times causing the occurrence to span midnight";
            ProfileUtil.resetThroughDate(this.repeatsThroughBean, this.profileModel, null, this.timeModel);
        }

        if (!this.dateError && !this.overlapError) {
            this.profileModel.throughDate = this.repeatsThroughBean.date;
            ProfileUtil.setThroughDate(this.repeatsThroughBean, this.profileModel, null);
            this.onChange();
        }
    }
    endsAfterChange() {
        this.resetErrors();
        this.profileModel.repeatEnd = "iterations";
        if (this.profileModel.endsAfter <= 0) {
            this.profileModel.endsAfter = 1;
        }

        if (ProfileUtil.hasOverlaps(this.timeModel, this.profileModel, this.repeatsThroughBean.date)) {
            this.overlapError =
                "Occurrence dates are overlapping. This can happen when repeating an overnight occurrence or from setup/pre/post/takedown times causing the occurrence to span midnight";
            this.profileModel.endsAfter = 1;
        }
        this.onChange();
    }

    onChange() {
        this.apiBeanChange.emit(this.apiBean);
    }

    ngOnInit(): void {
        this.hasIterations = this.patternEndChoices.includes("iterations");
        this.hasAnyEndDt = this.patternEndChoices.includes("none");
        this.hasDateEndDt = this.patternEndChoices.includes("date");
        this.repeatsThroughBean = {};
        this.repeatsOptions = [];
        for (var i = 1; i < 13; i++) {
            this.repeatsOptions.push({
                itemName:
                    (i === 1 ? "" : i === 2 ? "other " : i === 3 ? "3rd " : i + "th ") +
                    (this.type === "daily" ? "day" : this.type === "weekly" ? "week" : "month"),
                itemId: i,
            });
        }

        this.repeatByDayStarts = [];
        for (var i = 0; i < 31; i++) {
            this.repeatByDayStarts.push({
                itemName: i + " day" + (i !== 1 ? "s" : ""),
                itemId: i + 1, //add one to be compatible with profile codes since they save from 1 - 31, not 0 - 30
            });
        }

        this.profileModel = Object.assign(
            ProfileUtil.getProfileModel(
                this.type,
                this.profileCode,
                ProfileUtil.getDefaultThroughDate(this.timeModel),
                this.timeModel.evStartDt,
            ),
            this.profileModel,
        );

        ProfileUtil.setThroughDate(this.repeatsThroughBean, this.profileModel, null);
        this.apiBean.getProfileModel = function () {
            return this.profileModel;
        }.bind(this);
        this.apiBean.getProfileCode = () => {
            return ProfileUtil.getProfileCodeByModel(this.profileModel);
        };
        this.apiBean.getOccurrences = () => {
            return ProfileUtil.getOccurrences(this.timeModel, this.profileModel);
        };

        this.cd.detectChanges();
        this.init = true;
    }
}
